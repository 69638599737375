<template>
  <v-alert
    border="top"
    colored-border
    :color="processo.curriculo_completo ? 'success' : 'warning'"
    elevation="3"
    dense
  >
    <div class="d-flex flex-column flex-md-row align-center">
      <div class="d-flex flex-column align-start">
        <h1 class="text-h5 font-weight-medium black--text">Currículo</h1>
        <p class="text-body-1 pa-0 ma-0">
          {{
            processo.curriculo_completo
              ? "Seu perfil está bem preenchido e de acordo com o esperado."
              : "Coloque mais informações ou atualize seu perfil para favorecer seu currículo."
          }}
        </p>
      </div>

      <v-spacer />

      <v-chip
        class="mt-6 mt-md-0"
        :color="processo.curriculo_completo ? 'success' : 'warning'"
      >
        {{
          processo.curriculo_completo ? "Perfil completo" : "Perfil incompleto"
        }}
      </v-chip>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "AlertCurriculo",
  computed: {
    processo: {
      get() {
        return this.$store.getters["resProcessosCandidatos/getProcesso"];
      },
    },
  },
};
</script>

<style></style>
