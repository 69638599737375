import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"my-6"},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"small":"","color":"secondary","outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,[_vm._v("mdi-chevron-left")]),_vm._v(" voltar ")],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-center justify-space-between"},[_c('div',{staticClass:"d-flex flex-column align-center align-md-start"},[_c('h1',{staticClass:"text-h4 font-weight-bold black--text text-center"},[_vm._v(" "+_vm._s(_vm.processo.titulo)+" ")]),_c('h2',{staticClass:"text-subtitle-1 font-weight-regular black--text"},[_vm._v(" Faça o acompanhamento das etapas do seu processo seletivo ")])]),_c('p',{staticClass:"pa-0 text-caption black--text mt-5 mt-md-0"},[_vm._v(" Publicado em "+_vm._s(_vm._f("dateTimeFormatBr")(_vm.processo.created_at,null, "LLL"))+"h ")])])]),_c(VCol,{staticClass:"my-8",attrs:{"cols":"12"}},[_c('ProgressBar')],1),_c(VCol,{staticClass:"my-3",attrs:{"cols":"12"}},[_c('AlertCurriculo')],1),_c(VCol,{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('ProcessCard')],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c('h1',{staticClass:"primary--text text-h4 font-weight-medium mb-3"},[_vm._v("Carregando")]),_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }