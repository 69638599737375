import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex flex-row overflow-x-auto pa-2"}),_c(VTabs,{staticStyle:{"box-shadow":"none !important"},attrs:{"background-color":"transparent","next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","color":"primary","show-arrows":"","height":"9em"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_vm._l((_vm.processo.processos),function(item,i){return _c(VTab,{key:i,attrs:{"href":'#tab-' + i}},[_c(VAlert,{staticClass:"mx-3 my-0",attrs:{"elevation":"3","width":"19em","border":"left","colored-border":"","color":_vm.status[item.status].color}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"17em"}},[_c('h3',{staticClass:"font-weight-medium text-h6"},[_vm._v(_vm._s(item.nome))]),_c('div',{staticClass:"d-flex align-end justify-end my-2"},[_c(VChip,{attrs:{"color":_vm.status[item.status].color}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.status[item.status].icon))]),_vm._v(" "+_vm._s(_vm.status[item.status].text)+" ")],1)],1)])])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }