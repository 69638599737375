<template>
  <section>
    <div class="d-flex flex-row overflow-x-auto pa-2"></div>

    <v-tabs
      background-color="transparent"
      style="box-shadow: none !important"
      next-icon="mdi-chevron-right"
      prev-icon="mdi-chevron-left"
      color="primary"
      show-arrows
      height="9em"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab
        v-for="(item, i) in processo.processos"
        :key="i"
        :href="'#tab-' + i"
      >
        <v-alert
          elevation="3"
          class="mx-3 my-0"
          width="19em"
          border="left"
          colored-border
          :color="status[item.status].color"
        >
          <div style="width: 17em" class="d-flex flex-column">
            <h3 class="font-weight-medium text-h6">{{ item.nome }}</h3>
            <div class="d-flex align-end justify-end my-2">
              <v-chip :color="status[item.status].color">
                <v-icon class="mr-2">{{ status[item.status].icon }}</v-icon>
                {{ status[item.status].text }}
              </v-chip>
            </div>
          </div>
        </v-alert>
      </v-tab>
    </v-tabs>
  </section>
</template>

<script>
export default {
  name: "ProcessCard",
  data() {
    return {
      status: {
        APRNE: {
          text: "Aprovado",
          icon: "mdi-check",
          color: "success",
        },
        RPRNE: {
          text: "Reprovado",
          icon: "mdi-close",
          color: "error",
        },
        NANE: {
          text: "Não avaliado",
          icon: "mdi-minus",
          color: "secondary",
        },
      },
    };
  },
  computed: {
    processo: {
      get() {
        return this.$store.getters["resProcessosCandidatos/getProcesso"];
      },
    },
  },
};
</script>

<style></style>
