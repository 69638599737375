<template>
  <div class="d-flex flex-column">
    <h3 class="font-weight-medium mb-2">Seu progresso</h3>
    <div class="d-flex flex-row align-center">
      <v-progress-linear
        color="info"
        height="7"
        rounded
        background-color="#ddd8d4"
        :value="returnPercentProgresso"
      />
      <p class="pa-0 ma-0 ml-3 text-body-1 font-weight-medium">
        {{
          processo && processo.progresso
            ? `${processo.progresso.progresso_atual}/${processo.progresso.progresso_maximo}`
            : "0/0"
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  computed: {
    processo: {
      get() {
        return this.$store.getters["resProcessosCandidatos/getProcesso"];
      },
    },
    returnPercentProgresso() {
      if (!this.processo || !this.processo.progresso) return 0;

      const result =
        (this.processo.progresso.progresso_atual /
          this.processo.progresso.progresso_maximo) *
        100;

      return result;
    },
  },
};
</script>

<style></style>
