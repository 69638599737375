<template>
  <v-row class="my-6">
    <v-col class="pb-0" cols="12">
      <v-btn
        small
        class="ma-2"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        <v-icon>mdi-chevron-left</v-icon> voltar
      </v-btn>
    </v-col>
    <!-- Titulo e created_at -->
    <v-col cols="12">
      <div
        class="d-flex flex-column flex-md-row align-center justify-space-between"
      >
        <div class="d-flex flex-column align-center align-md-start">
          <h1 class="text-h4 font-weight-bold black--text text-center">
            {{ processo.titulo }}
          </h1>
          <h2 class="text-subtitle-1 font-weight-regular black--text">
            Faça o acompanhamento das etapas do seu processo seletivo
          </h2>
        </div>
        <p class="pa-0 text-caption black--text mt-5 mt-md-0">
          Publicado em
          {{ processo.created_at | dateTimeFormatBr(null, "LLL") }}h
        </p>
      </div>
    </v-col>
    <!-- Progresso -->
    <v-col cols="12" class="my-8">
      <ProgressBar />
    </v-col>
    <!-- Card Curriculo -->
    <v-col cols="12" class="my-3">
      <AlertCurriculo />
    </v-col>
    <v-col cols="12" class="mt-6">
      <ProcessCard />
    </v-col>
    <v-overlay :value="loading">
      <h1 class="primary--text text-h4 font-weight-medium mb-3">Carregando</h1>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-overlay>
  </v-row>
</template>

<script>
import ProgressBar from "../components/ProgressBar.vue";
import AlertCurriculo from "../components/AlertCurriculo.vue";
import ProcessCard from "../components/ProcessCard.vue";

export default {
  name: "Processo",
  components: { ProgressBar, AlertCurriculo, ProcessCard },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    const uuid = this.$route.params.uuid;

    this.$store
      .dispatch("resProcessosCandidatos/getProcessDetail", uuid)
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    processo: {
      get() {
        return this.$store.getters["resProcessosCandidatos/getProcesso"];
      },
    },
  },
};
</script>

<style></style>
